/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import cx from "classnames";
import { graphql, navigate } from "gatsby";
import { useAlerts } from "../../context";
import { Accordion } from "../../components/Accordion";
import { FaqsTypeahead } from "../../components/FaqsTypeahead";
import { getFaqData } from "../../components/FAQSearch/utils/getFaq";
import { BaseLayout } from "../../components/Layouts/BaseLayout";
import { Section } from "../../components/Section";
import { Heading } from "../../components/Heading";
import { Link } from "../../components/Link";

import { Icon } from "../../components/Icons";
import { getFaqSubCatURL } from "../../components/FAQSearch/utils/getFaqSubCatURL";
import { RCL as useTranslation } from "../../components/RCL";
import * as styles from "./faq.module.scss";
import { RichTextConnector } from "../../connectors/RichTextConnector";

const getQaJson = d => {
	const dt = d?.map(v => v?.node?.faqItems);
	const result = [];
	// eslint-disable-next-line array-callback-return
	dt.map(a => {
		// eslint-disable-next-line array-callback-return
		a?.map(i => {
			const answer = documentToPlainTextString(JSON.parse(i?.answer?.raw));
			const question = i?.question;
			const contentful_id = i?.contentful_id;
			result.push({
				"@type": "Question",
				contentful_id,
				name: question,
				acceptedAnswer: {
					"@type": "Answer",
					text: answer,
				},
			});
		});
	});
	return result;
};

const QA = ({ data, pageContext, location }) => {
	const [searchParams, setSearchParams] = useState("");

	const backButtonLabel = useTranslation({
		searchKey: "faqs-home",
	});
	/* Retrieve Page Data from the page query */
	const altLangPages = data.allSitePage;
	const { homepagePaths, defaultHeader, travelAlertsUrl, defaultFooter, faqsData } = data;

	const { alertsCount } = useAlerts();

	const { categories, displayTitle } = faqsData?.edges?.[0]?.node;
	const locale = pageContext?.locale.substring(0, 2).toLowerCase();
	const qaJson = data ? getQaJson(data?.faqCategory?.edges) : [];
	/* Use the FluidSection component to build out the fluid page sections */

	const submitHandler = urlParams => {
		setSearchParams(urlParams);
		if (urlParams) navigate(`/${locale}/faqs/search/?q=${urlParams}`);
		else navigate(`/${locale}/faqs/search`);
	};

	return (
		<BaseLayout
			location={location}
			pageContext={pageContext}
			seo={{ robots: ["index", "follow"] }}
			altLangPages={altLangPages}
			footerData={defaultFooter}
			headerNavigation={defaultHeader}
			homepagePaths={homepagePaths}
			alerts={alertsCount}
			travelAlertsUrl={travelAlertsUrl?.path}
			pageType="Other" // TODO:: Fix me
		>
			<Section>
				<Heading as="h1" size="h1">
					{displayTitle}
				</Heading>
				<div className={styles.searchTypeAhead}>
					<FaqsTypeahead
						className={styles.faqPageSearch}
						data={getFaqData(categories)}
						submitHandler={submitHandler}
						searchParams={searchParams}
						locale={locale}
						isIcon={false}
					/>
				</div>
				<div className={styles.backToHome}>
					<Heading as="span" size="h3">
						<Link to={`/${pageContext?.locale?.substring(0, 2)}/faqs`}>
							<Icon name="arrow-left" className={styles.backToHomeIcon} />
							{backButtonLabel}
						</Link>
					</Heading>
				</div>
				<div className={styles.faqContent}>
					<div className={cx(styles.colCategory, styles.faqContentNav)}>
						<Accordion
							className={styles?.qaTab}
							tabTheme="qa"
							activeKey={pageContext.category_ctf_id}
							tabData={categories?.map(category => ({
								key: category?.contentful_id,
								toggle: { label: { heading: category?.displayTitle } },
								panel: (
									<div className={styles.subCategoryMenu}>
										{category?.subCategories?.map(subCategory => (
											<Heading
												key={subCategory.contentful_id}
												as="div"
												size="base"
												className={cx(
													pageContext.ctf_id === subCategory.contentful_id &&
														styles.subCategoryActive
												)}
											>
												<Link
													className={styles?.link}
													to={getFaqSubCatURL(
														pageContext?.locale?.substring(0, 2),
														subCategory.displayTitle
													)}
												>
													{subCategory?.displayTitle}
												</Link>
											</Heading>
										))}
									</div>
								),
							}))}
						/>
					</div>
					<div className={cx(styles?.colContent, styles?.col, styles.faqContentContent)}>
						{data?.faqCategory?.edges?.map(subCategory => (
							<div key={subCategory.contentful_id}>
								<Heading as="h2" size="h2" className={styles.subCategoryTitle}>
									{subCategory?.node?.displayTitle}
								</Heading>

								<Accordion
									variant="qa"
									activeKey={subCategory?.node?.faqItems?.[0]?.contentful_id}
									tabData={subCategory?.node?.faqItems?.map(qa => ({
										key: qa?.contentful_id,
										panel: <RichTextConnector data={qa?.answer} />,
										toggle: { label: { heading: qa?.question } },
									}))}
								/>
							</div>
						))}
					</div>
				</div>
				<Helmet>
					<script type="application/ld+json">
						{JSON.stringify(
							{
								"@context": "https://schema.org",
								"@type": "FAQPage",
								mainEntity: qaJson,
							},
							null,
							4
						)}
					</script>
				</Helmet>
			</Section>
		</BaseLayout>
	);
};

/* Declare page prop */
QA.propTypes = {
	data: PropTypes.object.isRequired,
	pageContext: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export default QA;

/* Query page data using the pageSections GraphQL fragment included in the imported queryFluidSection file */
export const pageQuery = graphql`
	query GetQALayoutPageData($id: String!, $locale: String!, $rclLocale: String!, $ctf_id: String!) {
		faqsData: allContentfulFaq(
			filter: {
				node_locale: { eq: $rclLocale }
				application: { elemMatch: { application3charCode: { eq: "SOV" } } }
			}
		) {
			edges {
				node {
					...faqsWithQAs
				}
			}
		}
		faqCategory: allContentfulFaqCategory(filter: { id: { eq: $id } }) {
			edges {
				node {
					...faqCategoryWithQA
				}
			}
		}
		homepagePaths: allSitePage(filter: { context: { template: { eq: "homepage" } } }) {
			edges {
				...homepagePaths
			}
		}
		allSitePage(filter: { context: { ctf_id: { eq: $ctf_id } } }) {
			edges {
				...LanguageToggle
			}
		}
		defaultHeader: contentfulHeaderNavigation(
			contentful_id: { eq: "4WLelYKXDOXBf7CGGtf52z" }
			node_locale: { eq: $locale }
		) {
			...headerNavigation
		}
		defaultFooter: contentfulFooter(
			contentful_id: { eq: "3TjYjWvnKS88bdse66t4oo" }
			node_locale: { eq: $locale }
		) {
			...Footer
		}
		travelAlertsUrl: sitePage(context: { template: { eq: "alerts" }, locale: { eq: $locale } }) {
			path
		}
	}
`;
